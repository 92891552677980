.video-border {
  border: 4px solid white;
  max-width: 70vw;
  margin: auto;
}

video {
  width: 100%;
  height: 100%;
  display: block;
  max-height: 70vh;
  background-color: #000000;
}

.video-controls {
  right: 0;
  left: 0;
  padding: 0.9rem;
  transition: all 0.2s ease;
  background-color: #e9e9e9;
  display: grid;
  grid-template-columns: 7% 80% 12%;
  align-items: center;
  justify-content: space-between;

  img {
    width: 1.6rem;
    height: 1.6rem;
    fill: #46565D;
    stroke: #46565D;
    cursor: pointer;
  }
}

.video-progress {
  position: relative;
  height: 0.25rem;
  margin: 0.75rem 0;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  width: 100%;
  height: 0.25rem;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  background-color: #46565D;
}

progress::-webkit-progress-bar {
  background-color: #474545;
  border-radius: 2px;
}

progress::-webkit-progress-value {
  background: #74B943;
  border-radius: 2px;
}

progress::-moz-progress-bar {
  border: 1px solid #74B943;
  background: #74B943;
}

.seek {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

button {
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
}

.playback-animation {
  position: absolute;
  top: calc(50% - 4rem);
  transform: translate(-50%, -50%);
  left: 50%;
  width: 13rem;
  height: 13rem;
  border-radius: 150px;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  img {
    width: 4rem;
    height: 4rem;
    margin-left: 15px;
  }
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 0.25rem;
  background: transparent;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 1.3px;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

input[type=range]::-webkit-slider-thumb {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  background: url('button.png') center center;
  background-size: cover;
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: -1px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.hidden {
  display: none;
}

.time {
  width: 100%;
  font-size: 0.825rem;
  color: #46565D;
  font-family: "Barlow Semi Condensed", sans-serif;
}
