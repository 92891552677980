.nav-button {
  text-align: right;

  img {
    display: block;
  }
}

.prev {
  transform: rotate(180deg);
}
