.popup {
  position: absolute;
  padding: 1.5rem 0.8rem 1rem 1.5rem;
  background-color: #3C343B;
  border: 2px solid #ffffff;
  width: 19rem;
  height: auto;
  z-index: 9999;

  & > div {
    overflow: hidden;
  }

  p {
    font-size: 0.75rem;
    color: #ffffff;
  }

  h2 {
    font-size: 1rem;
    color: #74B943;
    margin-bottom: 0.5rem;
    margin-top: 0;
    text-transform: uppercase;
    font-family: "Fibra One Alt";
    font-weight: bold;
    line-height: 1;
  }

}

.popup--1 {
  &:before {
    top: 100%;
    left: 50%;
  }

  &:after {
    top: calc(100% + 2rem);
    left: 50%;
    transform: skewY(25deg);
  }
}
