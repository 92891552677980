.text {
  color: #ffffff;

  p {
    margin: 2rem 0;
  }

  h3 {
    font-weight: bold;
  }
}
