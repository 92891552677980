.with-back-button {
  position: absolute;
}

.label {

}

.content {
  display: grid;
  grid-auto-flow: column;
  grid-gap: .5rem;
  padding: 0.1rem 0.9rem;
  align-items: center;
}
