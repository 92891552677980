* {
    font-size: 1.67vh;
    line-height: 2.22em;
}

.fz-1 {
    font-size: 2.25rem;
    line-height: 1.2;
}

.fz-3 {
    font-size: 1.35rem;
}

.fz-5 {
    font-size: 0.8rem;
    line-height: 1.3;
}

h1 {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: bold;
}

p {
    font-family: "Barlow Semi Condensed", sans-serif;
    margin-top: 0;
    line-height: 1.1;
}

i {
    font-size: inherit;
    line-height: inherit;
}

.color-white {
    color: #ffffff;
}

.font-alt {
    font-family: "Fibra One Alt";
}

.d-block {
    display: block;
}

.text-center {
    text-align: center;
}

.shadow {
    box-shadow: 0.6rem 0.6rem 1.2rem #3a3a3a;
}

.upper {
    text-transform: uppercase;
}

.slide {
    overflow: hidden;
}

ion-alert .alert-wrapper.sc-ion-alert-md {
    width: 20vw;
    max-width: 30rem;
}
