.custom-carousel {
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-gap: #{"min(4rem, 64px)"};
    align-items: center;
    overflow: visible !important;
  }

  &--gap-14 > div {
    grid-gap: 14rem;
  }

  .slider {
    align-items: center;

    .slide {
      overflow: hidden;
      padding: 1px;
    }
  }
}

