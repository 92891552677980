.hotspot-container {
  position: absolute;
  z-index: 999;

  &--is-open {
    .hotspot {
      transform: rotate(0);
      background-color: #3C343B;
    }

    .pulse {
      border-color: #3C343B;
    }
  }

  &:nth-of-type(2n) {
    .pulse {
      animation-delay: 0.2s;
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
    }
  }
  &:nth-of-type(3n) {
    .pulse {
      animation-delay: 0.4s;
      &:nth-of-type(2) {
        animation-delay: 1.4s;
      }
    }
  }
  &:nth-of-type(4n) {
    .pulse {
      animation-delay: 0.6s;
      &:nth-of-type(2) {
        animation-delay: 1.6s;
      }
    }
  }
}

.hotspot {
  transform: rotate(45deg);
  padding: 0.8rem;
  background-color: #74B943;
  border-radius: 50%;
  width: 2.5rem;
  transition: 0.3s;
}

.pulse {
  position: absolute;
  border-radius: 50%;
  border-width: 0.2rem;
  border-style: solid;
  border-color: #74B943;
  animation: pulse 2.5s infinite;
  width: 2.5rem;
  height: 2.5rem;
  top: 0;
  left: 0;
  background-color: #ffffff60;

  &:nth-of-type(2) {
    animation-delay: 1s;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    width: 2.5rem;
    height: 2.5rem;
    top: 0;
    left: 0;
  }

  80% {
    left: -0.8rem;
    top: -0.8rem;
    opacity: 0.3;
    width: 4.1rem;
    height: 4.1rem;
  }

  100% {
    left: -1rem;
    top: -1rem;
    opacity: 0;
    width: 4.5rem;
    height: 4.5rem;
  }
}
