.game {
  text-align: left;
  margin: 2px;

  &__title {
    font-size: 2rem;
  }

  &__description {
    color: #ffffff;
  }
}
