.overlay-container {
  width: 100%;
  height: 100%;
  position: relative;
  border: 4px solid #ffffff;

  &__absolute {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
  }
}

.text {
  color: #ffffff;

  p {
    margin: 2rem 0;
  }

  h3 {
    font-weight: bold;
  }
}

.resize {

  > div {
    width: 5px !important;
    background-color: #ffffff;

    &:after {
      content: '';
      background-image: url('btn-next.svg');
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width: 5rem;
      height: 5rem;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}
