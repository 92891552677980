.button {
  border: 3px solid #FFFFFF;
  color: #ffffff;
  text-transform: uppercase;
  box-shadow: 0.6rem 0.6rem 1.2rem #3a3a3a;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  &--primary {
    background-image: linear-gradient(#5F9A35, #90D162);
  }

  &--secondary {
    background-image: linear-gradient(#3C343B, #655663);
  }

  &--default {
    background-image: linear-gradient(#46565D, #37444A);
  }
}
