.card {
  border: 4px solid #ffffff;
  position: relative;
  animation: highlight;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;

  &__name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #46565db0;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;

    strong {
      font-size: 1.5rem;
    }
  }

  img {
    display: block;
  }

  @keyframes highlight {
    from {
      box-shadow: 0px 0px 0px #000000;
    }

    to {
      box-shadow: 0px 0px 5rem #000000;
    }
  }
}
